@import "react-big-calendar/lib/addons/dragAndDrop/styles.scss";
@import "react-big-calendar/lib/sass/styles";
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #121828 !important;
}

a {
  color: #a16ae8;
  text-decoration: none;
}

#sentry-feedback {
  --inset: auto auto 0 0;
}

.rbc-time-header {
  margin-top: 16px;
  margin-left: 70px;
}

.rbc-time-header-gutter {
  margin: 0 24px 0 24px;
}

.rbc-time-gutter {
  margin: 0 24px 0 24px;
  .rbc-timeslot-group {
    border-bottom: none !important;
    border-left: none !important;
  }
}
.rbc-day-slot {
  .rbc-timeslot-group {
    border-bottom: 2px solid #000 !important;
    border-left: none !important;
  }
}
.rbc-time-header-content {
  .rbc-allday-cell {
    display: none !important;
  }
}

.rbc-header {
  border-bottom: none !important;
}
.rbc-time-content {
  border-top: none !important;
  overflow: hidden;
}

.rbc-time-header-content {
  border-left: none !important;
}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-calendar {
  background-color: #fff;
}
.rbc-toolbar {
  display: none;
}

.rbc-time-view {
  border: none !important;
  gap: 16px;
}

.rbc-addons-dnd-resize-ns-icon {
  opacity: 0;
  width: 100% !important;
}

.rbc-event-label {
  display: none;
}

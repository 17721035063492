:root {
  --fc-border-color: #ffd96a;
}

.fc {
  .fc-header-toolbar {
    display: none;
  }

  a {
    color: #121828;
  }

  .fc-col-header-cell {
    padding: 5px;
    font-weight: normal;
  }

  .fc-daygrid {
    border: 2px solid #ffd96a;
    border-radius: 8px;
  }

  .fc-daygrid-day-top {
    flex-direction: row;
  }

  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
  }
  .fc-daygrid-day-events {
    font-size: 14px;
    cursor: default;
    margin-top: auto;
    margin-bottom: 2px;
    margin-right: 1px;
    .fc-daygrid-event-harness:hover {
      opacity: 0.6;
    }
  }
}

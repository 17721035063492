.layout-container {
  height: calc(100vh - 94px);
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.layout-body {
  margin-top: 20px;
  flex-grow: 1;
}

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;700&display=swap");

//All Global Styles

* {
  font-family: "Plus Jakarta Sans", sans-serif;
}

html,
body {
  font-size: 1em;
  font-family: "Plus Jakarta Sans", sans-serif;
  background-color: #f2f4f6;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

$white: #ffffff;
$black: #000000;
$table-header-bg: #ccd5fc;

.main {
  margin: 80px 10px 0px 245px;
}
#__react-alert__ div div div {
  width: auto !important;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.company_logo {
  width: 30px !important;
  height: 30px !important;
  margin-right: 10px;
  border-radius: 15px !important;
}

.profile_btn {
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
  border: none;
  // border: 2px solid gray;
  color: #002dff;
  background-color: white;
  padding: 8px 20px;
  //border-radius: 8px;
  font-size: 14px;
  //font-weight: bold;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.css-18jfu8d-MuiDivider-root {
  margin-top: 6px !important;
  margin-left: 16px !important;
  margin-right: 16px !important;
}

#zohohc-asap-web-launcherbox {
  position: fixed !important;
  left: 0 !important;
}
//nav items

nav {
  a {
    color: #000000;
    display: block;
    padding: 0.1rem 1rem;
    margin: 0 0 0.3rem 0;
    font-size: 14px;
    font-weight: 500;
    height: 42px;
    display: flex;
    align-items: center;

    svg {
      vertical-align: middle;
      margin-right: 0.5rem;
      border-radius: 50%;
      padding: 0.5rem;

      .fill-color {
        fill: #000000;
      }

      .stroke-color {
        stroke: #000000;
      }
    }

    &:hover {
      background: #f2f4f6;
      color: #a16ae8;
      cursor: pointer;

      svg .fill-color {
        fill: #a16ae8;
      }

      svg .stroke-color {
        stroke: #a16ae8;
      }
    }
  }

  a.active {
    cursor: pointer;
    background: #f2f4f6;
    color: #a16ae8;
    position: relative;

    svg {
      display: inline;
      border-radius: 50%;
      padding: 0.5rem;
    }

    svg .fill-color {
      fill: #a16ae8;
    }

    svg .stroke-color {
      stroke: #a16ae8;
    }
  }

  a.active::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    border-radius: 0 10px 10px 0px;
    background-color: #a16ae8;
  }

  .sub-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      padding-left: 58px;
    }
  }

  .sub-menu-toggle svg {
    margin: 0;
  }
}

//table
.tbl-container {
  background-color: $white;
  border-radius: 10px;

  .tbl-head {
    background: rgba(204, 213, 252, 0.5);

    th {
      font-size: 14px;
      font-weight: 500;
      color: $black;
      border: none;
      height: 25px;
      vertical-align: middle;
      text-transform: capitalize;

      &:first-child {
        border-radius: 8px 0px 0px 8px;
      }

      &:last-child {
        border-radius: 0px 8px 8px 0px;
      }
    }
  }

  .tbl-body {
    tr {
      td {
        vertical-align: baseline;
        font-size: 14px;
        border-bottom: 1px solid #8a969a;
      }
    }
  }
}

.css-endbxa-MuiPaper-root-MuiDrawer-paper {
  border-right: 0px solid #e6e8f0 !important;
}

fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
}

fieldset.MuiSelect-outlined {
  border-color: #000 !important;
}

.card-tbl {
  display: block;

  .tbl-card {
    background-color: $white;
    box-shadow: 1px 4px 20px rgba(132, 132, 132, 0.2);
    border-radius: 10px;
  }

  .tbl-card-skeleton {
    background-color: $white;
    box-shadow: 1px 4px 20px rgba(132, 132, 132, 0.2);
    border-radius: 10px;
    min-width: 350px;
    margin-top: 16px;
  }

  .tbl-header {
    background: #fffaea;
    border-radius: 10px;
    padding: 0.5rem;
  }

  .tbl-header-disable {
    padding: 0.5rem;
  }

  .tbl-exe-header {
    background: #fffaea;
    border-radius: 10px;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }

  .css-tq42yy-MuiGrid-root {
    margin-top: -33px;
  }

  .tbl-body {
    padding: 1rem;
  }

  .tbl-body-disable {
    opacity: 0.3;
    background: #ecedf2;
    padding: 1rem;
  }
}

.responsive-tbl {
  display: none;

  .tbl-card {
    background-color: $white;
    box-shadow: 1px 4px 20px rgba(132, 132, 132, 0.2);
    border-radius: 10px;
  }

  .tbl-card-skeleton {
    background-color: $white;
    box-shadow: 1px 4px 20px rgba(132, 132, 132, 0.2);
    border-radius: 10px;
    min-width: 350px;
    margin-top: 16px;
  }

  .tbl-header {
    background: #fffaea;
    border-radius: 10px;
    padding: 0.5rem;
  }

  .tbl-header-disable {
    padding: 0.5rem;
  }

  .tbl-exe-header {
    background: #fffaea;
    border-radius: 10px;
    padding-top: 0.5rem;
    padding-left: 0.5rem;
  }

  .css-tq42yy-MuiGrid-root {
    margin-top: -33px;
  }

  .tbl-body {
    padding: 1rem;
  }

  .tbl-body-disable {
    opacity: 0.3;
    background: #ecedf2;
    padding: 1rem;
  }
}

//page header
.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
  border-color: rgb(7, 7, 7);
}
.page-header {
  border-radius: 10px;
}

.page-header-blue {
  background-color: #ccd5fc;
  padding: 20px 20px;
  border-radius: 10px 10px 0 0;
}

.page-header-blue-timesheet {
  background-color: #ccd5fc;
  padding: 10px 10px;
  border-radius: 10px 10px 0 0;
}

.MuiTab-root {
  text-transform: initial !important;
}
.mobile-page-header-blue {
  background-color: #ccd5fc;
  padding: 10px 10px;
  border-radius: 5px;
}

.page-header-white {
  background-color: #ffffff;
  padding: 20px 20px;
  border-radius: 10px;
}

.page-header-pink {
  background-color: #f7ebfc;
  padding: 20px 20px;
  border-radius: 10px;
}

//same height card
.same-height {
  height: 92px;
  padding: 12px;
  box-sizing: border-box;
  .total-cards-content {
    display: flex;
    padding: 0 !important;
    justify-content: space-between !important;
    height: 100%;
    align-items: center;
  }
  .calander-cards-content {
    padding: 0 !important;
    justify-content: space-between !important;
  }
}

.logoutBtn {
  border: none;
  background: none;
  outline: none;
  margin: 0;
  line-height: 1.5;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  padding: 0px;
}

.debounceInput {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding-top: 17.5px;
  padding-right: 14px;
  padding-bottom: 17.5px;
  padding-left: 14px;
  &:hover {
    border: 1px solid #000;
  }
}

.pendingInvoiceHeader {
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  justify-content: space-between;
}

.leaveDashboardBox {
  display: flex !important;
  justify-content: space-between;
}

.leaveDashboardResBox {
  display: none !important;
}

@media screen and (max-width: 768px) {
  body {
    margin-bottom: 7em !important;
  }
  .main {
    margin: 80px 0px 0px 0px;
  }

  .leaveDashboardResBox {
    display: block !important;
    margin-top: 15px;
  }

  .responsive-tbl {
    display: block;
  }

  .tbl-container {
    display: none;
  }
  .logo_rbl {
    // position: fixed;
    // left: 35%;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-items: center;
    text-align: center;
  }
  // img {
  //   height: 32px !important;
  // }

  h3 {
    font-size: 20px !important;
  }

  h4 {
    font-size: 14px !important;
  }
  .MuiTypography-root {
    font-size: 0.8rem !important;
  }
  .pendingInvoiceHeader {
    display: block;
    margin-left: 16px;
  }
  .pendingInvoiceHeaderAmount {
    margin-top: 5px;
    font-size: 0.9rem !important;
  }

  .pendingInvoiceHeaderViewAll {
    font-size: 0.7rem !important;
  }

  .jobActivity {
    margin-top: -30px;
  }

  .jobText {
    font-size: 0.9rem !important;
  }
  .jobTextTeamMember {
    font-size: 0.9rem !important;
    margin-left: -10px;
  }

  .jobTextTeamMemberCount {
    margin-right: -15px;
  }

  .leaveDashboardBox {
    display: none !important;
  }

  .same-height {
    height: 60px;
    padding: 8px;
    box-sizing: border-box;
    .total-cards-content {
      display: flex;
      padding: 0 !important;
      justify-content: space-between !important;
    }
    .calander-cards-content {
      padding: 0 !important;
      justify-content: space-between !important;
    }
  }
  .responsiveImage {
    height: 15px !important;
  }
}

//scrollbar
.customScroll {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #121828;
    border: 2px solid #555555;
  }
}

.formik-input-div {
  .error {
    color: red;
    margin-top: 8px;
    display: block;
    font-size: 14px;
  }
}

.formik-input-div {
  margin-bottom: 15px;
}

.full-width {
  width: 100%;
}

.customize-data-grid-pagination {
  background: #fff;
  padding: 6px 4px;
  border-top: solid 1px rgb(224, 224, 224);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  label {
    font-size: 14px;
    margin-right: 12px;
  }
  .MuiSelect-select {
    padding: 6.5px 12px;
  }
}

.custom-modal {
  top: 50%;
  left: 50%;
  width: 52%;
  outline: none;
  padding: 24px;
  position: absolute;
  transform: translate(-50%, -50%);
  min-height: 150px;
  border-radius: 10px;
  background-color: white;
  h2 {
    font-size: 24px !important;
    font-weight: 700;
  }
  button {
    font-weight: 400;
  }
}
.custom-snackbar-msg {
  color: #fff !important;
}

.MuiTooltip-tooltip {
  background-color: #fff !important;
  color: #000 !important;
  box-shadow: 0px 2px 3px #bfbfbf;
  .MuiTooltip-arrow {
    color: #fff !important;
  }
}

.leave-container .MuiBox-root {
  margin-left: 0;
  margin-right: 0;
}

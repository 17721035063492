.radio-button-performance {
  .MuiFormGroup-row {
    margin-top: 12px;
  }

  @media screen and (max-width: 768px) {
    label {
      margin-left: -16px;
      margin-right: 15px;
    }

    font-size: 12px !important;
  }
}
.salary-increase-flax {
  display: flex;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.annual-feedback-details-header {
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
  }
}
.annaul-feedback-details-header {
  @media screen and (max-width: 768px) {
    border-radius: 0px !important;
    padding: 0px 0px !important;
  }
}

.annaul-feedback-details-layout {
  @media screen and (max-width: 768px) {
    padding: 0px 0px !important;
  }
}

.annual-feedback-mention-amount-input {
  margin-right: 15px;
  width: 20rem;
  .MuiFormControl-root {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.month-dropdown {
  .MuiFormControl-root {
    width: 20rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.annual-feedback-details-filled-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  > div:not(:last-child) {
    border-bottom: 1px solid #707d84;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-bottom: solid 2px #000 !important;
    border-radius: 0px !important;
  }
  .Mui-disabled {
    opacity: 10 !important;
    -webkit-text-fill-color: #000 !important;
  }
}

.disabled-employee-name {
  .Mui-disabled {
    opacity: 10 !important;
    -webkit-text-fill-color: #000 !important;
  }
}

.performance-custom-tabs {
  .MuiButtonBase-root {
    font-weight: 500;
    font-size: 16px;
    color: #000;
    @media screen and (max-width: 768px) {
      font-size: 13px;
      padding: 3px 14px;
    }
  }
  margin-top: 0 !important;
}
.annaul-feedback-mobile-table {
  .MuiTypography-caption {
    display: block;
    margin-top: 8px;
    font-size: 14px !important;
  }
}
.performance-table-avatar-name {
  display: flex;
  align-items: center;
  .row-names {
    font-weight: 700;
    color: #000;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .MuiAvatar-root {
    border: solid 3px #ccd3d5;
    border-radius: 50%;
    margin-right: 5px;
  }
  @media screen and (max-width: 768px) {
    .MuiAvatar-root {
      border: solid 1px #ccd3d5;
      width: 25px;
      height: 25px;
    }
  }
}
.performance-alert-bg {
  background: #f7dbb1 !important;
  color: #000 !important;
  text-align: center;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;

  .MuiAlert-message {
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 768px) {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 7px !important;
    border-bottom-right-radius: 7px !important;
  }
}
.feedback-details-header {
  display: flex;
  h2 {
    font-size: 28px;
    @media screen and (max-width: 768px) {
      font-size: 19px;
    }
  }
  button {
    padding: 0px;
    svg {
      font-size: 4rem;
      color: #000;
      @media screen and (max-width: 768px) {
        font-size: 2.5rem;
      }
    }
  }
  button:hover {
    background: none;
  }
}

.annual-feedback-details-body {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: #ffffff;
  color: #121828;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow:
    0px 1px 1px rgba(100, 116, 139, 0.06),
    0px 1px 2px rgba(100, 116, 139, 0.1);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0.05)
  );
  padding: 10px 15px 21px;

  @media screen and (max-width: 768px) {
    margin: 16px 10px 0px;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    padding: 10px 1px 21px;
    h6 {
      font-size: 12px !important;
    }
    .MuiRating-icon {
      svg {
        width: 0.8em;
        height: 0.8em;
      }
    }
  }
}

.annaul-feeback-no-filled-form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 15px;
  @media screen and (max-width: 768px) {
    display: block;
    h5 {
      font-size: 16px !important;
    }
    h6 {
      font-size: 12px !important;
      margin-top: 8px;
    }
  }
}

.custom-form-control {
  width: 100%;
  margin-bottom: 10px !important;
}
.comment-box-custom-form-control {
  width: 100%;
  margin-bottom: 25px !important;
}
.radio-button-form-control {
  margin: 15px 0px !important;
}
.rating-form-control {
  margin: 10px 0px;
}
.performance-header-mobile-view {
  background-color: #fff !important;
  padding: 0px 0px !important;
}

.performance-toolbar-mobile {
  background-color: #ccd5fc;
  padding: 19px 3px;
  margin-top: 33px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.filter-switch-performance-details {
  padding: 0px 17px;
  .MuiTypography-root {
    font-size: 14px;
  }
}
.error-message-annaul-feedback {
  font-size: 26px;
  text-align: center;
  font-weight: bold;
  color: red;
}

.performance-page-layout {
  @media screen and (max-width: 768px) {
    padding: 0px !important;
  }
}
.allPerformanceTableMobile {
  @media screen and (max-width: 768px) {
    padding: 0px 11px !important;
  }
}
.data-grid-custom-error-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  h4 {
    font-weight: 400;
  }
}

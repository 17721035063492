.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-container {
  display: flex;
  gap: 20px;

  > div {
    width: 100%;
    min-width: 300px;
    max-width: 300px;
  }

  .MuiInputBase-input {
    text-transform: capitalize !important;
  }
}

.MuiList-root .MuiButtonBase-root {
  text-transform: capitalize !important;
}

.header-button {
  text-align: center;
  font-family: "Plus Jakarta Sans";
  padding: 10px 24px;
  font-size: 14px;
  font-style: normal;
  line-height: 20px !important; /* 142.857% */
  letter-spacing: 0.1px !important;
}

.hire-list-container {
  height: calc(100vh - 190px);
}

.hire-list {
  background-color: #f2f4f6;

  .MuiDataGrid-columnHeadersInner {
    background-color: #fff;
  }

  .MuiDataGrid-virtualScroller {
    margin-top: 62px !important;
  }

  .MuiDataGrid-row {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.05);
    margin: 5px 0px;
    background-color: #fff !important;
  }

  .MuiDataGrid-cell {
    border-bottom: none !important;
  }

  .MuiDataGrid-cell--textCenter {
    overflow: visible !important;
  }
}

.hire-row {
  display: flex;
  flex-direction: column;
  gap: 6px;
  overflow: visible !important;
  justify-content: space-between;
  padding: 12px 0;
}

.align-center {
  align-items: center;
}

.dummy-space {
  height: 20px;
}

.fake-link {
  color: #000000;
}

.common-select-dropdown .MuiFormControl-root {
  width: 100%;
}

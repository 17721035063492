.interview-list-page-sub-header {
  display: flex;
  background-color: #f0e9fc;
  align-items: center;
  justify-content: space-between;
  padding: 11px 15px 9px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  h3 {
    font-size: 18px;
    margin: 0px;
    font-weight: 500;
  }
  .icon-div-box {
    display: flex;
    div {
      margin: 0px 7px;
    }
  }
}
.summary-candiate-box {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  min-height: 120px;
  padding: 16px;
  justify-content: center;
  align-items: center;
  margin-top: 11px;
  h3 {
    font-size: 14px;
    font-weight: 400;
  }
}
.inteview-questions-list-accorditions-box {
  margin-top: 10px;
  .only-question-div {
    background: #fff;
    padding: 13px 16px;
    box-shadow:
      0px 1px 1px rgba(100, 116, 139, 0.06),
      0px 1px 2px rgba(100, 116, 139, 0.1);
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05)
    );
  }
  .accordion-div {
    margin: 12px 0px;
    border-radius: 6px;
    .accordion-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .MuiAccordionSummary-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      h5 {
        font-size: 14px;
        font-weight: 500;
      }
      button {
        font-size: 14px;
        font-weight: 400;
        width: 110px;
        padding: 0px 0px;
      }
    }
    .MuiAccordionDetails-root {
      .answer-div {
        .hints-answer {
          display: flex;
          align-items: center;
          span {
            font-size: 12px;
            font-style: italic;
            margin-left: 8px;
            margin-right: 8px;
          }
          svg {
            width: 18px;
            height: 18px;
            cursor: pointer;
          }
        }
        p {
          font-size: 14px;
          font-weight: 400;
          margin-top: 9px;
        }
      }
      .notes-div {
        margin-top: 22px;
        p {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}

.question-list-tabs {
  .MuiTabs-indicator {
    background: none !important;
  }
}
.question-add-modal-popup {
  width: 520px !important;
}
.candiate-client-box {
  background-color: #f0e9fc;
  padding: 13px 18px;
  border-radius: 5px;
  .common-select-dropdown {
    margin-top: 12px;
  }
  .like-button {
    background: #00e08c !important;
    color: #fff;
  }
  .unlike-button {
    background: #e61a1a !important;
    color: #fff;
  }
  .selected {
    border: solid 1px #a16ae8;
  }
  .icon-div-box {
    div {
      padding: 3px 3px;
      border-radius: 50%;
    }
  }
}

.save_submit_box {
  float: right;
  margin-top: 23px;
  margin-bottom: 40px;
}
.endorsement-result {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  padding: 0px 22px;
  margin-bottom: 20px;
}
.tooltip-ul-candiate-info {
  padding-left: 0px;
  li {
    list-style: none;
    display: flex;
    font-size: 14px;
    margin-bottom: 6px;
    .label-span {
      width: 85px;
      display: inline-block;
    }
  }
}
.tooltip-candidate-info-box > .MuiTooltip-tooltip{
  max-width: 450px !important;
}
.index-view {
  display: flex;
  align-self: flex-start;
  margin-right: 8px;
}
.question-view {
  display: flex;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

#spinner {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s;
  visibility: visible;
  opacity: 1;
}
#spinner img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
#spinner h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  color: #ffffff;
  padding: 30px;
}
